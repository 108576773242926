<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/sales/tasks')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="date" class="form-label required">
            التاريخ
            <span>*</span>
          </label>

          <Calendar
            style="width: 100%;"
            v-model="body.date"
            :class="{ 'is-invalid': isInvalid && !body.date }"
            dateFormat="dd-mm-yy"
            :minDate="minDateValue"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="clientName" class="form-label required">
            اسم العميل
            <span>*</span>
          </label>
          <input
            class="form-control"
            id="clientName"
            v-model="body.clientName"
            :class="{ 'is-invalid': isInvalid && !body.clientName }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="status" class="form-label required">
            الحاله
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="status"
            name="status"
            v-model.number="body.status"
            :class="{ 'is-invalid': isInvalid && !body.status }"
            required
          >
            <option value="1">غير مهتم</option>
            <option value="2">اهتمام بسيط</option>
            <option value="3">مهتم</option>
            <option value="4">مهتم جدا</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="countriesId" class="form-label required">
            الدوله
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="countriesId"
            name="countriesId"
            v-model.number="body.countriesId"
            :class="{ 'is-invalid': isInvalid && !body.countriesId }"
            required
          >
            <option
              v-for="item of countriesList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="citiesId" class="form-label required">
            المدينه
            <span>*</span>
          </label>

          <select
            class="form-select form-control"
            id="citiesId"
            name="citiesId"
            v-model.number="body.citiesId"
            :class="{ 'is-invalid': isInvalid && !body.citiesId }"
            required
          >
            <option
              v-for="item of citiesList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="address" class="form-label required">
            العنوان
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="address"
            v-model="body.address"
            :class="{ 'is-invalid': isInvalid && !body.address }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label required">
            الهاتف
            <span>*</span>
          </label>
          <input
            type="tel"
            class="form-control"
            id="phone"
            v-model="body.phone"
            :class="{ 'is-invalid': isInvalid && !body.phone }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="who" class="form-label required">
            النشاط
            <span>*</span>
          </label>
          <input
            class="form-control"
            id="who"
            v-model="body.who"
            :class="{ 'is-invalid': isInvalid && !body.who }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="managerName" class="form-label required">
            اسم المسئول
            <span>*</span>
          </label>
          <input
            class="form-control"
            id="managerName"
            v-model="body.managerName"
            :class="{ 'is-invalid': isInvalid && !body.managerName }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="managerPhone" class="form-label required">
            جول المسئول
            <span>*</span>
          </label>
          <input
            type="tel"
            class="form-control"
            id="managerPhone"
            v-model="body.managerPhone"
            :class="{ 'is-invalid': isInvalid && !body.managerPhone }"
            required
          />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="desc" class="form-label">
            وصف العميل
          </label>
          <b-form-textarea
            id="textarea-small"
            name="desc"
            v-model="body.desc"
            placeholder=""
          ></b-form-textarea>
        </div>

        <div class="mb-3">
          <label for="note" class="form-label">
            ملاحظات
          </label>
          <b-form-textarea
            id="textarea-small"
            name="note"
            v-model="body.note"
            placeholder=""
          ></b-form-textarea>
        </div>

        <div class="mb-3 text-center">
          <Button
            label="تعديل"
            icon="pi pi-pencil"
            v-tooltip="'تعديل'"
            class="p-ml-2 p-button-info"
            :disabled="disabledAdd"
            @click="update()"
            v-if="id"
          />
          <Button
            v-else
            label="اضافة جديد"
            icon="pi pi-plus"
            v-tooltip="'اضافه جديد'"
            class="p-ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const user = JSON.parse(localStorage.admin);

    const d = new Date();
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        date: `${ye}-${mo}-${da}`,
        usersId: user.id,
        clientName: null,
        status: 1,
        countriesId: null,
        citiesId: null,
        address: null,
        phone: null,
        who: null,
        managerName: null,
        managerPhone: null,
        desc: null,
        note: null,
      },
      id: null,
      countriesList: [],
      citiesList: [],
      clientGroupsList: [],
      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    save() {
      if (
        this.body.date &&
        this.body.clientName &&
        this.body.countriesId &&
        this.body.citiesId &&
        this.body.address &&
        this.body.phone &&
        this.body.who &&
        this.body.managerName &&
        this.body.managerPhone
      ) {
        this.disabledAdd = true;
        this.$http.post(`tasks`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافة بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/sales/tasks');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
    update() {
      if (
        this.body.date &&
        this.body.clientName &&
        this.body.countriesId &&
        this.body.citiesId &&
        this.body.address &&
        this.body.phone &&
        this.body.who &&
        this.body.managerName &&
        this.body.managerPhone
      ) {
        this.disabledAdd = true;

        this.$http.put(`tasks/${this.id}`, this.body).then(
          () => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/sales/tasks');
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
    if (!this.$checkRoles('tasksAdd')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`countries`).then((res) => {
      this.countriesList = res.data;
    });

    this.$http.get(`company`).then(
      (res) => {
        this.body.countriesId = res.data.countriesId.id;
        this.body.citiesId = res.data.citiesId.id;
      },
      (err) => {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: err.response.data.message,
          life: 3000,
        });
      },
    );

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`tasks/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.countriesId = res.data.countriesId.id;
          this.body.citiesId = res.data.citiesId.id;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
  watch: {
    'body.countriesId'(val) {
      this.$http.get(`cities?countriesId=${val}`).then((res) => {
        this.citiesList = res.data;
      });
    },
  },
};
</script>

<style>
.firstSupport label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
